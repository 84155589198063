import '../css/index.scss';
import {auto} from "html-webpack-plugin/lib/chunksorter";

new Promise((resolve) => {
  require.ensure(['./libs/owl.carousel.min.js'], () => {
    resolve(require('./libs/owl.carousel.min.js'));
  }, 'owl.carousel.min');
}).then(() => {
  function initSlider() {
    if(($('.past-event').length > 7 )&& (window.innerWidth > 1340)) {
      $('.past-events__slider ').addClass('owl-carousel');
      $('.past-events__slider ').owlCarousel({
        nav: true,
        loop: true,
        items: 3,
        dots: false,
      })
    } else if(window.innerWidth < 1340) {
      $('.past-events__slider ').owlCarousel('destroy');
      $('.past-events__slider ').removeClass('owl-carousel');
    }
  }
  initSlider()
  $(window).on('resize', function() {
    clearTimeout(id);
    var id = setTimeout(initSlider, 500);
  })




  $('.video-btn').on('click', function () {
    $('.video-popup').addClass('_active');
  })
  $('.close-btn').on('click', function () {
    $('#video')[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    $('.video-popup').removeClass('_active');
  })
});


$(window).on("load", function() {
  $('.front-content__bg-meteors--1').addClass('met-1')
  $('.front-content__bg-meteors--2').addClass('met-2')
  $('.front-content__bg-meteors--3').addClass('met-3')
  $('.front-content__bg-meteors--4').addClass('met-4')
  $('.front-content__bg-meteors--5').addClass('met-5')
  $('.front-content__bg-meteors--6').addClass('met-6')
  $('.front-content__bg-meteors--7').addClass('met-7')
  if($('.event__item').length === 1) {
    $('.event__item').addClass('event__item--1')
  }
  if($('.event__item').length === 2) {
    $('.event__item').addClass('event__item--2')
  }
  if($('.event__item').length === 3) {
    $('.event__item').addClass('event__item--3')
  }
});
